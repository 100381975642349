<template>
  <div>
    <div>
      <v-btn icon :to="{ name: 'portfolio' }" small>
        <v-icon>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <span class="title">{{ item.title }}</span>
    </div>

    <v-container>
      <v-row>
        <v-col
          xs="12"
          sm="12"
          md="6"
          lg="4"
          xl="3"
          v-for="(image, i) in photos"
          :key="`thumbnail_${i}`">
          <v-spacer />
          <v-card
            height="100%"
            elevation="0">
            <v-container
              fill-height
              fluid>
              <v-row
                align="center"
                justify="center">
                <v-col>
                  <v-img
                    :src="image"
                    contain
                    @click="index = i"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <v-spacer />
        </v-col>
        <v-spacer />
      </v-row>
      <v-row>
        <v-col>
          <div class="text-caption">
            <vue-markdown :source="item.details" v-if="item.details" />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <vue-gallery-slideshow :images="photos" :index="index" @close="index = null" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import VueMarkdown from 'vue-markdown-render';
import VueGallerySlideshow from 'vue-gallery-slideshow';

import openGraphImage from '../mixins/OpenGraphImage';

export default {
  props: {
    viewedId: Number,
  },
  components: {
    VueMarkdown,
    VueGallerySlideshow,
  },
  mixins: [openGraphImage],
  data() {
    return {
      item: {},
      photos: [],
      index: null,
    };
  },
  computed: {
    ...mapState([
      'portfolioItems',
      'baseUrl',
    ]),
  },
  mounted() {
    this.item = this.portfolioItems.find((e) => e.id === this.viewedId);
    this.photos = this.item.photos.map((image) => `${this.baseUrl}/${this.item.folder}/${image.source}`);

    document.title += ` ${this.item.title}`;
    this.setImage(`${this.baseUrl}/${this.item.folder}/${this.item.mainPhoto}`);
  },
};
</script>

<style lang="scss" scoped>
div {
  user-select: none;
}
</style>
