<template>
  <v-container>
    <v-row>
      <v-spacer />
      <v-col
        cols="6"
        md="6"
        sm="12"
        lg="4"
        xl="3"
        v-for="item in portfolioItems"
        :key="item.id"
        class="news-item">
        <v-card height="100%">
          <v-card-subtitle>
            <v-container>
              <v-row>
                <router-link :to="{ name: 'portfolio-item', params: { viewedId: item.id } }">
                  {{ item.title }}
                </router-link>
                <v-spacer />
                <v-btn icon x-small :href="`/portfolio/view/${item.id}`" target="_blank">
                  <v-icon>
                    mdi-open-in-new
                  </v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </v-card-subtitle>
          <v-container>
            <v-row
              align="center"
              justify="center">
              <v-spacer />
              <v-col>
                <router-link :to="{ name: 'portfolio-item', params: { viewedId: item.id } }">
                  <v-img
                    :src="`${baseUrl}/${item.folder}/${item.cover}`"
                    contain />
                </router-link>
              </v-col>
              <v-spacer />
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState([
      'portfolioItems',
      'baseUrl',
    ]),
    imageDimension() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 220;
        case 'sm': return 400;
        case 'md': return 500;
        case 'lg': return 600;
        case 'xl': return 800;
        default: return 500;
      }
    },
  },
  data() {
    return {
      windowSize: {
        x: 0,
        y: 0,
      },
    };
  },
  mounted() {
    this.onResize();
  },
  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  user-select: none;
}
</style>
